@media (min-aspect-ratio: 10/10){
	.serviceContainer{
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		box-sizing: border-box;
		padding: 15vh 2vh 2vh;
		opacity: 1;
		animation: 1s linear appearSlowly;
		min-height: 300px;
		min-width: 600px;
		color: white;
		transition: opacity 0.25s;
	}
	.service{
		opacity: 1;
		transition: opacity 0.4s;
	}
	.serviceCard{
		height: 60vh;
		width: 28vw;
		border-radius: 1vw;
		background-color: #47677a;
		min-width: 350px;
		overflow: hidden;
		position: relative;
		min-height: 28vw;
	}
	.cardTitle{
		height: 10vh;
		width: 100%;
		background-color: #87a8b0;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		color: #3C485D;
	}
	.cardItem{
		width: 100%;
		height: calc((100% - 10vh) / 5);
		box-sizing: border-box;
		border-top: 2px solid #14232C;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		color: #BBD2C5;
		background-color: #47677a;
		font-size: 1.25vw;
		transition: color 0.25s, background 0.25s, font-size 0.25s;
		cursor: pointer;
	}
	.cardItem:hover{
		color: #47677a;
		background-color: #BBD2C5;
		font-size: 1.5vw;
	}
	.cardItem:active{
		background-color: #9FB7AA;
	}
	.serviceImage{
		width: 25vw;
		height: 25vw;
	}
	.contactBtn{
		display: inline-flex;
		flex-direction: column;
		justify-content: space-around;
		transition: 0.25s background-color, filter 0.25s;
		box-sizing: border-box;
		border-radius: 0.5vw;
		border: 2px solid white;
		background-color: #47677a;
		padding: 1vh 1vh 1vh;
		margin-top: 2vh;
		font-size: 1.5vw;
	}
	.contactBtn:hover{
		color: white;
		background-color: #9FB7AA;
	}
	.contactBtn:active{
		filter: brightness(0.8);
	}
	.contactPannel{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
		width: 100vw;
		height: 100vh;
		opacity: 0.8;
		background-color: white;
		visibility: hidden;
		opacity: 0;
		filter: blur(20vh);
		transition: opacity 0.5s;
	}
	.contactInfo{
		position: absolute;
		left: 35vw;
		top: 10vh;
		width:30vw;
		min-height: 80vh;
		border-radius: 1vw;
		background-color: #333333;
		overflow: hidden;
		z-index: 5;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.5s;
	}
	.contactInfoTop{
		height: 7.5vw;
		width: 100%;
		background-image: url("../public/assets/profileItemBackground.png");
		background-size: contain;
		background-repeat: no-repeat;
	}
	.contactInfoTop img{
		height: 7.5vw;
		width:100%;
	}
	.contactInfoBottom{
		display: flex;
		width: 100%;
		height:calc(80vh - 7.5vw);
		flex-direction: column;
		justify-content: space-around;
		color: white !important;
		font-size: 1.2vw;
		font-weight: bold;
		box-sizing: border-box;
		padding: 0 2vw 0;
		white-space: break-spaces;
	}
	.emailInp{
		width:25vw;
		height: 1.5vw;
		border-radius: 10px;
		font-size: 1.4vw;
		text-align: center;
		border: 3px solid gray;
		transition: border 0.5s;
	}
	.messageInp{
		width:25vw;
		height: 40vh;
		border-radius: 10px;
		resize: none;
		font-size: 1vw;
		padding: 0.5vh 0.5vh 0.5vh;
		border: 3px solid gray;
		transition: border 0.5s;
	}
	.submitBtn{
		border-radius: 10px;
		background-color: #0d161c;
		color: #00888f;
		padding: 3px 3px 3px;
		border: 2px solid #00888f;
		transition: color 0.25s, background 0.25s;
	}
	.submitBtn:hover{
		color: #0d161c;
		background-color: #00888f;
		border: 2px solid #0d161c;
	}
	.submitBtn:active{
		color: #00888f;
		background-color: #0d161c;
	}
	.confirmation{
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		color:white;
		background-color: green;
		font-size: 3vh;
		text-align: center;
	}
	.contactInfoOverlay{
		position: absolute;
		left: 35vw;
		top: 10vh;
		width:30vw;
		height: 80vh;
		opacity: 1;
		background-color: rgba(255,255,255, 0.5);
		border-radius: 1vw;
		z-index: 10;
		visibility: hidden;
		font-size: 5vw;
		color: gray;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
	}
	
	.closeContactBtn{
		width: 2.5vw;
		height: 2.5vw;
		border-radius: 50%;
		background-color: white;
		color: #14232C;
		font-size: 1.8vw;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
		cursor: pointer;
		position: absolute;
		top:2vh;
		right: 2vh;
		border: 2px solid white;
		transition: border 0.25s, background 0.25s, color 0.25s;
	}
	.closeContactBtn:hover{
		background-color: #14232C;
		color: white;
		border: 2px solid white;
	}
	.closeContactBtn:active{
		filter: brightness(0.9);
	}
	.cardOverlay{
		width: 100%;
		height: 100%;
		position:absolute;
		top: 0;
		left:0;
		background-color: white;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.5s;
		background-color: #BBD2C5;
	}
	.cardOverlayBody{
		color: #47677a;
		background-color: #BBD2C5;
		height: 50vh;
		width: 100%;
		box-sizing: border-box;
		padding: 1vw 1vw 1vw;
		font-size: 1.05vw;
		white-space: pre-wrap;
	}
	.backBtn{
		width: 2vw;
		height: 2vw;
		border-radius: 50%;
		background-color: #BBD2C5;
		color: #14232C;
		font-size: 1.2vw;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
		cursor: pointer;
		position: absolute;
		top: 0.3vw;
		right: 0.3vw;
		transition: border 0.25s, background 0.25s, color 0.25s;
	}
	.backBtn:hover{
		background-color: #14232C;
		color: #BBD2C5;
	}
	.backBtn:active{
		filter: brightness(0.5);
	}
	.priceTag{
		display: inline-block;
		text-align: left;
		font-size: 1.2vw;
		width: 20%;
		box-sizing: border-box;
		padding: 1vw 2vw 0;
		font-weight: bold;
		color: #3C485D;
	}
	.priceValue{
		display: inline-block;
		text-align: right;
		font-size: 1.2vw;
		width: 80%;
		box-sizing: border-box;
		padding: 1vw 2vw 0;
		font-weight: bold;
		color: #3C485D;
	}
	.bigScreenOnly{
		display: block;
	}
	.smallScreenOnly{
		display: none;
	}
	.infoFotter{
		position: absolute;
		bottom: 5vh;
		left: 0;
		width: 100%;
		color: white;
		background-color: rgba(71, 103, 122, 0.5);
		font-size: 1.2vw;
		padding: 1vh 0 1vh;
		animation: 1s linear appearSlowly forwards;
		cursor: default;
	}
	.prePayment{
		width: 100%;
		height: 89vh;
		position: absolute;
		padding:0 35vw 0;
		top: 6vh;
		z-index: 3;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		background-color: transparent;
		box-sizing: border-box;
		animation: 0.25s linear appearSlowly forwards;
	}
	.prePaymentImg{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
		background-color: transparent;
		box-sizing: border-box;
		background-color: rgba(28, 48, 61, 0.8);
	}
	.prePaymentContainer{
		background-color: white;
		box-sizing: border-box;		width: 30vw;
		box-shadow: 0 0 1vw black;
		z-index: 4;
		border-radius: 0.5vw;
	}
	.prePaymentInput{
			box-sizing: border-box;
			width: 95%;
			font-size: 1vw;
			margin: 0 2.5% 0;
			border-radius: 0.5vw;
			outline: none;
			border: 0.15vw solid black;
			color: #00888f;
			text-align: center;
	}
	.serviceBtn{
		width: 30%;
		margin: 0.5vw 1.5% 0.5vw;
		background-color: black;
		color: white;
		padding: 0.5vw 1vw 0.5vw;
		border-radius: 5px;
		transition: color 0.25s, background 0.25s;
		font-size: 1vw;
		display: inline-block;
	}
	.serviceBtn:hover{
		background-color: #00888f;
	}
	.serviceBtn:active{
		color: black;
	}
	.deleteCodeBtn{
		width: 30%;
		margin: 0.5vw 1.5% 0.5vw;
		background-color: black;
		color: white;
		padding: 0.5vw 1vw 0.5vw;
		border-radius: 5px;
		transition: color 0.25s, background 0.25s;
		font-size: 1vw;
		display: inline-block;
	}
	.deleteCodeBtn:hover{
		background-color: #a32424;
	}
	.deleteCodeBtn:active{
		color: black;
	}
	.prePaymentMask{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
		background-color: rgba(0, 136, 143, 0.5);
		visibility: hidden;
		animation: 1s linear appearSlowly;
		color: white;
		font-size: 30vw;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		padding-top: 5vh;
	}
	.paymentContainer{
		background-color: white;
		box-sizing: border-box;
		width: 30vw;
		box-shadow: 0 0 1vw black;
		display: none;
		z-index: 4;
		border-radius: 0.5vw;
	}
	.paymentRow{
		width: 95%;
		margin: 0.5vw 2.5% 0.5vw;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		font-size: 1vw;
	}
	.paymentRowItem{
		min-width:30%;
		max-width: 100%;
	}
	.paymentRowHeader{
		font-weight: bold;
	}
	.paymentInput{
		width: 80%;
		margin: 1vw 10% ;
		padding: 0.25vw 0 0.25vw;
		font-size: 1.25vw !important;
		box-sizing: border-box;
		text-align: center;
		border: 0.15vw solid black;
		border-radius: 0.25vw;
	}
	.paymentInputHalf{
		width: 35%;
		margin: 1vw 5% 0;
		padding: 0.25vw 0 0.25vw;
		font-size: 1.25vw !important;
		display: inline-block;
		outline: none;
		box-sizing: border-box;
		text-align: center;
		border: 0.15vw solid black;
		border-radius: 0.25vw;
	}



	.readMe{
		color: #00888f;
		text-decoration: underline;
		font-weight: bold;
		font-size: 1.3vw;
		cursor: pointer;
		padding-bottom: 1vw;
	}
	.readMe:hover{
		color: blue;
	}
	.readMeCard{
		width: 25vw;
		height: 80vh;
		position: absolute;
		top: 10vh; 
		right: 5vw;
		background-color: white;
		z-index: 3;
		border-radius: 0.5vw;
		box-shadow: 0 0 1vw black;
	}
	.readMeCardHeader{
		position: absolute;
		height: 10vh;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		top: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 0 1vw 0;
	}
	.readMeCardBody{
		height: 60vh;
		width: 90%;
		margin: 0 5% 0;
		background-color: #1c1c1c;
		position: absolute;
		top: 10vh;
		color: #00888f;
		font-size: 1vw;
		overflow: auto;
		text-align: left;
		white-space: pre-wrap;
		box-sizing: border-box;
		padding: 1vh 1vw 1vh;
		border: 1px solid #1c1c1c;
		scroll-behavior: smooth;
		cursor: auto;
	}
	.readMeCardFooter{
		position: absolute;
		height: 10vh;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		bottom: 0;
		width: 80%;
		margin: 0 10% 0;
		box-sizing: border-box;
		padding: 1vh 10% 1vh;
		padding: 1vw 0 1vw;
	}
	.readMeCardFooteLeft{
		width:80%;
		display: inline-block;
		font-size: 0.8vw;
		text-align: center;
		cursor: default;
	}
	.readMeCardFooteRight{
		width:20%;
		display: inline-block;
		cursor: pointer;
	}
}
@media (max-aspect-ratio: 10/10){
	.serviceContainer{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		opacity: 0;
		animation: 1s linear appearSlowly forwards;
		color: white;
		transition: opacity 0.49s;
	}
	.serviceCard{
		height: 60vh;
		width: 100vw;
		background-color: #47677a;
		overflow: hidden;
		position: relative;
		margin-top: 2vh;
	}
	.cardTitle{
		height: 10vh;
		width: 100%;
		background-color: #87a8b0;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		color: #3C485D;
		font-size: 1.8vh;
	}
	.cardItem{
		width: 100%;
		height: calc((100% - 10vh) / 5);
		box-sizing: border-box;
		border-top: 2px solid #14232C;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		color: #BBD2C5;
		background-color: #47677a;
		font-size: 3vh;
		transition: color 0.25s, background 0.25s, font-size 0.25s;
		cursor: pointer;
	}
	.cardItem:hover{
		color: #47677a;
		background-color: #BBD2C5;
		font-size: 1.5vw;
	}
	.cardItem:active{
		background-color: #9FB7AA;
	}
	.serviceImage{
		width: 50vw;
		height: 50vw;
		padding-top: 2vh;
	}
	.contactBtn{
		display: inline-flex;
		flex-direction: column;
		justify-content: space-around;
		transition: 0.25s background-color, filter 0.25s;
		box-sizing: border-box;
		border-radius: 1vh;
		border: 2px solid white;
		background-color: #47677a;
		padding: 1vh 1vh 1vh;
		margin-top: 2vh;
		font-size: 3vh;
		visibility: visible;
	}
	.contactPannel{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
		width: 100vw;
		height: 100vh;
		opacity: 0.8;
		background-color: white;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.5s;
	}
	.contactInfo{
		position: absolute;
		left: 10vw;
		top: 10vh;
		width:80vw;
		height: 80vh;
		border-radius: 3vh;
		background-color: white;
		overflow: hidden;
		z-index: 5;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.5s;
	}
	.contactInfoTop{
		height: 20vw;
		width: 100%;
		background-image: url("../public/assets/profileItemBackground.png");
		background-size: contain;
		background-repeat: no-repeat;
		border-bottom: 2px solid white;
	}
	.contactInfoTop img{
		height: 20vw;
		width:100%;
	}
	.contactInfoBottom{
		width: 100%;
		height:calc(70vw - 7.5vw - 2px);
		color: #47677a !important;
		font-size: 2vh;
		box-sizing: border-box;
		padding: 0 2vh 0;
		white-space: break-spaces;
	}
	.emailInp{
		width:70vw;
		height: 5vh;
		border-radius: 10px;
		font-size: 2.5vh;
		text-align: center;
		border: 3px solid black;
		transition: border 0.5s;
		color: white;
		background-color: black;
	}
	.messageInp{
		width:70vw;
		height: 40vh;
		border-radius: 10px;
		resize: none;
		font-size: 2vh;
		padding: 0.5vh 0.5vh 0.5vh;
		border: 3px solid black;
		transition: border 0.5s;
		color: white;
		background-color: black;
	}
	.submitBtn{
		border-radius: 10px;
		background-color: #0d161c;
		color: white;
		padding: 3px 3px 3px;
		border: 2px solid #0d161c;
		transition: color 0.25s, background 0.25s;
	}
	.submitBtn:hover{
		color: #0d161c;
		background-color: white;
		border: 2px solid #0d161c;
	}
	.submitBtn:active{
		color: white;
		background-color: #0d161c;
	}
	.confirmation{
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		text-align: center;
		color:white !important;
		background-color: green;
		font-size: 2vh;
	}
	.contactInfoOverlay{
		position: absolute;
		left: 10vw;
		top: 10vh;
		width:80vw;
		height: 80vh;
		opacity: 1;
		background-color: rgba(187, 210, 197, 0.5);
		border-radius: 3vh;
		z-index: 10;
		visibility: hidden;
		font-size: 20vh;
		color: #BBD2C5;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
	}
	.closeContactBtn{
		width: 4vh;
		height: 4vh;
		border-radius: 50%;
		background-color: white;
		color: #14232C;
		font-size: 3vh;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
		cursor: pointer;
		position: absolute;
		top:1vh;
		right: 1vh;
		border: 2px solid white;
		transition: border 0.25s, background 0.25s, color 0.25s;
		user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
	}
	.closeContactBtn:hover{
		background-color: #14232C;
		color: white;
		border: 2px solid white;
	}
	.closeContactBtn:active{
		filter: brightness(0.9);
	}
	.cardOverlay{
		width: 100%;
		height: 100%;
		position:absolute;
		top: 0;
		left:0;
		background-color: white;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.5s;
		background-color: #BBD2C5;
	}
	.cardOverlayBody{
		color: #47677a;
		background-color: #BBD2C5;
		height: 50vh;
		width: 100%;
		box-sizing: border-box;
		padding: 1vw 1vw 1vw;
		font-size: 2vh;
		
	}
	.backBtn{
		width: 5vh;
		height: 5vh;
		border-radius: 50%;
		font-size: 4vh;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
		cursor: pointer;
		position: absolute;
		bottom: 1vh;
		right: calc(50vw - 2.5vh);
		transition: border 0.25s, background 0.25s, color 0.25s;
		background-color: #14232C;
		color: #BBD2C5;
		-moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
	}
	.backBtn:active{
		filter: brightness(0.5);
	}
	.priceTag{
		display: inline-block;
		text-align: left;
		font-size: 3vh;
		width: 20%;
		box-sizing: border-box;
		padding: 1vw 2vw 0;
		font-weight: bold;
		color: #3C485D;
	}
	.priceValue{
		display: inline-block;
		text-align: right;
		font-size: 3vh;
		width: 80%;
		box-sizing: border-box;
		padding: 1vw 2vw 0;
		font-weight: bold;
		color: #3C485D;
	}
	.bigScreenOnly{
		display: none;
	}
	.smallScreenOnly{
		display: block;
	}
	.infoFotter{
		width: 100%;
		color: white;
		background-color: #47677a;
		font-size: 2.5vh;
		margin: 3vh 0 10vh;
		animation: 1s linear appearSlowly forwards;
	}


	.prePayment{
		width: 100%;
		height: 100%;
		position: absolute;
		padding:0 5vw 0;
		top: 0;
		z-index: 3;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		background-color: rgba(0,0,0, 0.5);
		box-sizing: border-box;
		animation: 0.25s linear appearSlowly forwards;
		color:black;
	}
	.prePaymentImg{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
		background-color: transparent;
		box-sizing: border-box;
		background-color: rgba(28, 48, 61, 0.8);
	}
	.prePaymentContainer{
		background-color: rgba(255,255,255, 0.9);
		box-sizing: border-box;		
		width: 90vw;
		box-shadow: 0 0 1vw black;
		z-index: 4;
		padding: 1vh 0.5vh 1vh;
		border-radius: 1vh;
		font-size: 4.5vw;
	}
	.prePaymentInput{
			box-sizing: border-box;
			width: 95%;
			font-size: 3vh;
			margin: 0 2.5% 0;
			border-radius: 1vh;
			outline: none;
			border: 0.15vw solid black;
			color: #00888f;
			text-align: center;
	}
	.serviceBtn{
		width: 30%;
		margin: 0.5vw 1.5% 0.5vw;
		background-color: black;
		color: white;
		padding: 0.5vw 1vw 0.5vw;
		border-radius: 5px;
		transition: color 0.25s, background 0.25s;
		font-size: 5vw;
		display: inline-block;
	}
	.serviceBtn:hover{
		background-color: #00888f;
	}
	.serviceBtn:active{
		color: black;
	}
	.deleteCodeBtn{
		width: 30%;
		margin: 0.5vw 1.5% 0.5vw;
		background-color: black;
		color: white;
		padding: 0.5vw 1vw 0.5vw;
		border-radius: 5px;
		transition: color 0.25s, background 0.25s;
		font-size: 5vw;
		display: inline-block;
	}
	.deleteCodeBtn:hover{
		background-color: #a32424;
	}
	.deleteCodeBtn:active{
		color: black;
	}
	.prePaymentMask{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
		background-color: rgba(0, 136, 143, 0.5);
		visibility: hidden;
		animation: 1s linear appearSlowly;
		color: white;
		font-size: 30vw;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		padding-top: 5vh;
	}
	.paymentContainer{
		background-color: rgba(255,255,255, 0.9);
		border-radius: 1vh;
		box-sizing: border-box;
		width: 90vw;
		box-shadow: 0 0 1vw black;
		display: none;
		z-index: 4;
		position: absolute;
		top:10vh;
		max-height: 82.5vh;
		overflow-y: auto;
	}
	.paymentRow{
		width: 95%;
		margin: 0.5vw 2.5% 0.5vw;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		font-size: 4vw;
	}
	.paymentRowItem{
		min-width:30%;
		max-width: 100%;
	}
	.paymentRowHeader{
		font-weight: bold;
	}
	.paymentInput{
		width: 80%;
		margin: 1vw 10% ;
		padding: 0.25vw 0 0.25vw;
		font-size: 1.25vw !important;
		box-sizing: border-box;
		text-align: center;
		border: 0.15vw solid black;
		border-radius: 0.25vw;
	}
	.paymentInputHalf{
		width: 35%;
		margin: 1vw 5% 0;
		padding: 0.25vw 0 0.25vw;
		font-size: 1.25vw !important;
		display: inline-block;
		outline: none;
		box-sizing: border-box;
		text-align: center;
		border: 0.15vw solid black;
		border-radius: 0.25vw;
	}



	.readMe{
		color: #00888f;
		text-decoration: underline;
		font-weight: bold;
		font-size: 2vh;
		cursor: pointer;
		padding-bottom: 1vh;
	}
	.readMe:hover{
		color: blue;
	}
	.readMeCard{
		width: 100vw;
		height: 50vh;
		position: absolute;
		top: 22.5vh; 
		background-color: white;
		z-index: 3;
		box-shadow: 0 0 1vw black;
	}
	.readMeCardHeader{
		position: absolute;
		height: 2.5vh;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		top: 0;
		width: 100%;
		box-sizing: border-box;
		font-size: 2vh;
	}
	.readMeCardBody{
		height: 42.5vh;
		width: 95%;
		margin: 0 2.5% 0;
		background-color: #1c1c1c;
		position: absolute;
		top: 2.5vh;
		color: #00888f;
		font-size: 3.5vw;
		overflow: auto;
		text-align: left;
		white-space: pre-wrap;
		box-sizing: border-box;
		padding: 1vh 1vw 1vh;
		border: 1px solid #1c1c1c;
		scroll-behavior: smooth;
	}
	.readMeCardFooter{
		position: absolute;
		height: 5vh;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		bottom: 0;
		width: 80%;
		margin: 0 10% 0;
		box-sizing: border-box;		
		padding: 1vw 0 1vw;
	}
	.readMeCardFooteLeft{
		width:80%;
		display: inline-block;
		font-size: 1.3vh;
		text-align: right;
	}
	.readMeCardFooteRight{
		width:20%;
		display: inline-block;
	}
	.readmeCloseBtn{
		position: absolute;
		top:-0.5vw;
		right:2vw;
		width: 5vw;
		z-index: 5;
	}
	.readMeHide{
		visibility: hidden;
	}
}
.spinObject{
	animation: 1s linear spin infinite;
}
@keyframes appearSlowly {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}