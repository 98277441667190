@media (min-aspect-ratio: 10/10){
	.homeContainer{
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		box-sizing: border-box;
		padding: calc(45vh - 15vw) 2vh 0;
		opacity: 1;
		animation: 2s linear appearSlowly;
		min-height: 300px;
		min-width: 600px;
		bottom:0;
		transition: opacity 0.25s;
	}
	.homeItem{
		width:30vw;
		height:30vw;
		color: white;
		position: relative;
	}
	.homeLogo{
		width:25vw;
		height:25vw;
		background-color: white;
		color: #0d161c;
		border-radius: 20px;
		box-shadow: 0 0 0 .5vh white, 0 0 0 1.5vh #14232C, 0 0 0 2vh #00888f, 0 0 1vw 1vw black;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		margin: 1vh 0 0;
		overflow: hidden;
		position: relative;
	}
	.homeLogo img{
		border-radius: 20px;
	}
	.homeLogo img:hover{
	}
	.homeLogoOverlay{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
		border-radius: 20px;
		opacity: 0;
		transition: opacity 0.25s;
		background-color: transparent;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		background-color: rgba(0, 0, 0, 0.6);
		cursor: pointer;
	}
	.homeLogoOverlay:hover{
		opacity:1;
	}
	.homeLogoOverlay p{
		font-size: 1.5vw;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.8);
	}
	.homeLogoOverlayVideo{
		height: 100%;
		box-sizing: border-box;
		background-color: transparent;
		box-shadow: inset 25vw 0vw 25vw -30vw #00888f,inset -25vw 0vw 25vw -30vw #00888f;
	}
	.squareItem{
		width:40%;
		height:40%;
		display: inline-flex;
		flex-direction: column;
		justify-content: space-around;
		transition: 0.25s background-color, filter 0.25s, border-radius 0.25s, box-shadow 0.25s;
		box-sizing: border-box;
		border-radius: 20px;
		border: 3px solid white;
		box-shadow: 0 0 0.5vw black;
		background-color: #14232C;
		margin: 1vh 1vh 1vh;
		overflow: hidden;
		filter: brightness(1);
		cursor: pointer;
		-webkit-user-select: none; /* Safari */        
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */
	}
	.squareItem:hover{
		filter: brightness(1);
		animation: swingOnce 0.4s;
		box-shadow:0 0 0.5vw black,0 0 0.75vw 0.1vw cyan;
	}
	.squareItem:active{
		background-color:#005d41;
		filter: brightness(0.8);
	}
	.socialLink{
		height:100%;
		margin: auto;
	}
	.squareItem p{
		position: absolute;
		bottom:4.8vw;
		font-size: 2vw;
		width: 100%;
		font-family: sans-serif;
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.leftHandItems{
		height:12.5vw;
		width: 90%;
		background-color: #14232C;
		overflow: hidden;
		display: inline-flex;
		box-sizing: border-box;
		margin: 1vh 0 0;
		border-top-left-radius: 8vh;
		border-bottom-right-radius: 8vh;
		border-top-right-radius: 1vh;
		border-bottom-left-radius: 1vh;
		border: 3px solid white;
		filter: brightness(1);
		box-shadow: 0 0 0.5vw black;
		transition: filter 0.25s, box-shadow 0.25s, border-radius 0.25s, transform 0.25s;
		cursor: pointer;
	}
	.leftHandItems:hover{
		filter: brightness(1);
		transform: translate(2vw,0);
		box-shadow:0 0 0.5vw black,0 0 0.5vw 0.2vw cyan;
	}
	.leftHandItems:active{
		box-shadow: 0 0 0.5vw black,0 0 0 0.35vh #005d41, 0 0 0 0.6vh white;
		filter: brightness(0.8);
	}
	.leftHandSubDivLeft{
		width: 30%;
		height: 100%;
		border-top-left-radius: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		margin: 0 1vh 0;
		background-color: #14232C;
	}
	.leftHandSubDivLeft img{
		width: 100%;
		display: inline-block;
	}
	.leftHandSubDivRight{
		width: 70%;
		height: 100%;
		border-bottom-right-radius: 0;
		overflow: hidden;
		box-sizing: border-box;
		background-color: #14232C;
	}
	.leftHandSubDivRightImg{
		width:100%;
		height: 100%;
		object-fit: fill;
		border-bottom-right-radius: 7vh;
	}
	.popUpTab{
		position: absolute;
		top: -100vh;
		left: 0;
		width: 100vw;
		height: 95vh;
		z-index: 5;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		transition: top 1s, bottom 2s;
		box-sizing: border-box;
		border-bottom: 3px solid #14232C;
		color: #14232C;
		visibility: hidden;
	}
	.popUpTabBody{
		width:100%;
		max-width: 2000px;
		height:100vh;
		background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,0.95) 45%, rgba(0,136,143,0.85) 100%);
	}
	.popUpTabBodyCover{
		width:100%;
		height: 20vh;
		box-sizing: border-box;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		object-fit: cover;
		object-position: right top;
	}
	.popUpTabBodyCoverLogo{
		position: absolute;
		top:1vh;
		left:calc(50vw - 9vh);
		width:16vh;
		height: 16vh;
		padding: 1vh 1vh 1vh;
		background-color: #14232C;
		border-radius: 30%;
		box-shadow: 0 0 0.5vw black;
	}
	.popUpTabTextContainer{
		width: 100%;
		height: 75vh;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		box-sizing: border-box;
		padding: 2vh 2vh 2vh;

	}
	.popUpTabTextCards{
		box-shadow: 0 0 1vw black, 0 0 0 0.25vw white inset, 0 0 0 0.5vw #14232C inset;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 70vh;
		box-sizing: border-box;
		padding: 1vw 3vw 1vw;
		font-size: calc(3.5vh - 10px);
		min-width: 40vw;
		max-width: 48vw;
		border-radius: 1vw;
		background-color: white;
	}
	.popUpTabTextCards h2{
		text-decoration: underline;
	}
	.popUpTabTextCardsImage{
		height: 40vw;
		max-width: 60vh;
		max-height: 60vh;
		object-fit: cover;
		object-position: right top;
		border-radius: 0.75vw;
	}
	.closeBtn{
		width: 5vh;
		height: 5vh;
		border-radius: 50%;
		background-color: #00888f;
		color: white;
		font-size: 3vh;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		cursor: pointer;
		position: absolute;
		top:2vh;
		right: 2vh;
		box-sizing: border-box;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		transition: background 0.25s, color 0.25s, border 0.25s, filter 0.25s;
		z-index: 3;
	}
	.closeBtn:hover{
		background-color: white;
		color: #00888f;
	}
	.closeBtn:active{
		filter: brightness(0.9);
	}
	.servicetBtn{
		position: absolute;
		bottom: -15vh;
		right: 0;
		width: 100vw;
		transition: 0.25s background-color, filter 0.25s;
		box-sizing: border-box;
		background-color: #00888f;
		padding: 1vh 1vh 1vh;
		margin-top: 2vh;
		font-size: 3vh;
		color: white;
		animation: 0.75s linear slideInServBtn forwards;
		animation-delay: 1s;
		box-shadow: 0 0 0.45vw 0.1vw black;
	}
	.servicetBtn:hover{
		color: white;
		background-color: #54B2C4;
	}
	.servicetBtn:active{
		filter: brightness(0.9);
	}
	.bigScreenOnly{
		display: block;
	}
	.smallScreenOnly{
		display: none;
	}
	.popUpLogo{
		width:8vw;
		height: 8vw;
		background-color: white;
		border-radius: 50%;
		position: absolute;
		top:calc(55vh - 4vw);
		left: 46vw;
		box-shadow: 0 0 0.5vw black;
		box-sizing: border-box;
		border: 0.3vw solid white;
	}
	.squareItemsOverlay{
		width: 35vw;
		height: 83vh;
		background: rgb(43,69,84);
background: linear-gradient(90deg, rgba(43,69,84,1) 50%, rgba(32,52,64,1) 100%);
		position: absolute;
		right: -35vw;
		z-index: 2;
		box-shadow: inset 0.25vw 0 0.5vw black;
		transition: right 0.5s;
		color: #00888f;
		font-size: 1.3vw;
		box-sizing: border-box;
		overflow: hidden;
		visibility: hidden;
	}
	.squareItemsOverlay img{
		height: 3vw;
		opacity: 0;
		animation: 0.5s linear appearSlowly forwards;
		animation-delay: 0.5s;
	}
	.squareItemsOverlay h1{
		background: rgb(29,54,69);
background: linear-gradient(90deg, rgba(29,54,69,1) 50%, rgba(20,35,44,1) 100%);
		box-shadow: inset 0.25vw 0.15vw 0.5vw black;
		padding: 1vh 1vh 1vh;
		font-size: 2.5vw;
		color: #00888f;
	}
	.squareItemsOverlayText{
		width: 100%;
		box-sizing: border-box;
		padding: 2vh 1vw calc(2vh + 2vw);
		overflow-y: auto;
		height:calc(89vh - 5.5vh - 6.5vw);
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		color: #87a8b0;
		opacity: 0;
		animation: 0.5s linear appearSlowly forwards;
		animation-delay: 1s;
	}
	.squareItemsOverlayFooter{
		width: 100%;
		display: inline-flex;
		flex-direction: row;
		justify-content: space-around;
		font-size: 2vw;
		box-sizing: border-box;
		padding: 2vh 10vw 1.5vh;
		position: absolute;
		bottom: 0;
		left: 0;
		background: rgb(29,54,69);
background: linear-gradient(90deg, rgba(29,54,69,1) 50%, rgba(20,35,44,1) 100%);
		box-shadow: inset 0.25vw -0.15vw 0.5vw black;
		color: white !important;
	}
	.squareItemsOverlayFooter a{
		color: #00888f;
		transition: color 0.5s;
		opacity: 0;
		animation: 0.5s linear appearSlowly forwards;
		animation-delay: 0.5s;
	}
	.squareItemsOverlayFooter a:hover{
		color: #00deeb;

	}
	.iconBar{
		width: 100%;
		position: absolute;
		bottom: -12vh;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		color: #00888f;
		box-sizing: border-box;
		font-size: 1.3vw;
		padding: 0 0 0;
		cursor: default;
		background-color: rgba(255, 255, 255, 0.5);
		opacity: 1;
		transition: opacity 0.25s;
		animation: 1.50s linear slideInIconBar forwards;
		animation-delay: 1.25s;
	}
	.iconBar p{
		width: calc(100vw / 6);
	}
	.contactBtnHome{
		position: absolute;
		color: white;
		width: 16.5vw;
		bottom:calc(50vh - 13vw);
		left: 41.75vw;
		background-color: #0d161c;
		z-index: 4;
		padding: 0.25vw 0.5vw 0.25vw;
		box-sizing: border-box;
		font-size: 1.2vw;
		font-weight: bold;
		border-radius: 0.25vw;
		cursor: pointer;
		box-shadow: 0 0 0 .25vh white, 0 0 0 0.75vh #14232C, 0 0 0 1vh #00888f, 0 0 0.5vw 0.5vw black;
		transition: transform 0.25s, filter 0.25s, background 0.25s, box-shadow 0.25s;
		-webkit-user-select: none; /* Safari */        
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */
	}
	.contactBtnHome:hover{
		transform: scale(1.1);
		background-color: #00888f;
	}
	.contactBtnHome:active{
		transform: scale(1.05);
		filter: brightness(0.8);
	}
	.emailPrompt{
		display: block;
		position: absolute;
		font-size: 2.5vh;
		font-weight: bold;
		top: 7vh;
		right: 1vw;
		color: white;
		z-index: 5;
		opacity: 0;
		transition: opacity 0.25s;
	}
}
@media (max-aspect-ratio: 10/10){
	.mobileContainer{
		height: 89vh;
	}
	.homeContainer{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		opacity: 0;
		animation: 1s linear appearSlowly forwards;
		transition: opacity 0.49s;
		overflow-x: hidden;
		padding-bottom: 10vh;
	}
	.homeItem{
		width:100vw;
		height:100vw;
		color: white;
		overflow-x: hidden;
		padding: 2vh 0 0;
		box-sizing: border-box;
	}
	.homeLogo{
		width:80vw;
		height:80vw;
		margin: 10vw 10vw 10vw;
		background-color: transparent;
		color: #0d161c;
		box-shadow: 0 0 0 .5vh white,0 0 5vw black;
		border-radius: 5vw;
	}
	.homeLogoOverlayVideo{
		width: 90%;
		box-sizing: border-box;
		background-color: #0d161c;
		border-radius: 2vh;
		border:3px solid white;
		box-shadow: 0 0 2.5vw black;
		margin: 1vh 0 4vh;
	}
	.squareItem{
		width:40%;
		height:40%;
		display: inline-flex;
		flex-direction: column;
		justify-content: space-around;
		transition: 0.25s background-color, filter 0.25s, border-radius 0.25s;
		box-sizing: border-box;
		border-radius: 20px;
		border: 2px solid white;
		background-color:#14232C;
		margin: 1vh 1vh 1vh;
		overflow: hidden;
		box-shadow: 0 0 2.5vw black;
		filter: brightness(1);
	}
	.squareItem p{
		position: absolute;
		bottom:0;
		font-size: 5vw;
		width: 100%;
		font-family: sans-serif;
		cursor: default;
		color:white;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 1vw 0 1vw;
	}
	.socialLink{
		height:100%;
		width: 100%;
		margin: auto;
	}
	.leftHandItems{
		height:20vh;
		width: 90%;
		background-color: #14232C;
		overflow: hidden;
		display: inline-flex;
		box-sizing: border-box;
		border-top-left-radius: 8vh;
		border-bottom-right-radius: 8vh;
		border-top-right-radius: 1vh;
		border-bottom-left-radius: 1vh;
		border: 2px solid white;
		filter: brightness(1);
		margin-bottom: 1vh;
		box-shadow: 0 0 2.5vw black;
		transition: filter 0.25s, box-shadow 0.25s;
	}
	.leftHandSubDivLeft{
		width: 30%;
		height: 100%;
		border-top-left-radius: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		margin: 0 1vh 0;
	}
	.leftHandSubDivLeft img{
		width: 100%;
		display: inline-block;
	}
	.leftHandSubDivRight{
		width: 70%;
		height: 100%;
		border-bottom-right-radius: 20px;
	}
	.leftHandSubDivRightImg{
		width:100%;
		height: 100%;
		object-fit: fill;
	}
	.popUpTab{
		position: absolute;
		top: -100vh;
		left: 0;
		width: 100vw;
		height: 89%;
		z-index: 4;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		transition: top 1s, bottom 2s;
		box-sizing: border-box;
		border-bottom: 3px solid #14232C;
		color: #14232C;
	}
	.popUpTabBody{
		width:100%;
		max-width: 2000px;
		height:100%;
		z-index: 4;
		background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,0.95) 45%, rgba(0,136,143,0.85) 100%);
	}
	.popUpTabBodyCover{
		width:100%;
		height: 15vh;
		box-sizing: border-box;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		object-fit: cover;
		object-position: left top;
	}
	.popUpTabBodyCoverLogo{
		position: absolute;
		top:1vh;
		left:calc(50vw - 5vh);
		width:10vh;
		height: 10vh;
		padding: 1vh 1vh 1vh;
		background-color: #14232C;
		border-radius: 2vh;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
	.popUpTabTextContainer{
		width: 100%;
		height: 75%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		padding: 2vh 0 2vh;
		overflow: hidden;
	}
	.popUpTabTextCards{
		box-shadow: 0 0 1vw black, 0 0 0 1vw white inset, 0 0 0 2vw #14232C inset;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 95%;
		padding: 1vh 2vw 1vh;
		font-size: 2.75vw;
		height: fit-content;
		margin: 2.5vw;
		box-sizing: border-box;
		background-color: white;
		border-radius: 1vh;
	}
	.popUpTabTextCards h2{
		text-decoration: underline;
	}
	.popUpTabTextCardsImage{
		height: 27vh;
		object-fit: cover;
		object-position: left top;
		border-radius: 1vh;
		margin: 1vh 01vh;
	}
	.closeBtn{
		width: 5vh;
		height: 5vh;
		border-radius: 50%;
		background-color: #14232C;
		color: white;
		font-size: 4vh;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		cursor: pointer;
		position: absolute;
		z-index: 4;
		top:1vh;
		right: 0.5vh;
		box-sizing: border-box;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		transition: background 0.25s, color 0.25s, filter 0.25s;
	}
	.servicetBtn{
		width: 100vw;
		transition: 0.25s background-color, filter 0.25s;
		box-sizing: border-box;
		background-color: #00888f;
		padding: 3vh 1vh 3vh;
		font-size:  2.4vh;
		color: white;
		border-bottom: 2px solid white;
	}
	.servicetBtn:hover{
		color: #005d41;
		border: 2px solid #005d41;
		background-color: white;
	}
	.servicetBtn:active{
		filter: brightness(0.8);
	}
	.bigScreenOnly{
		display: none;
	}
	.smallScreenOnly{
		display: block;
	}
	.popUpLogo{
		display: none;
	}

	.squareItemsOverlay{
		width: 100vw;
		height: 89%;
		background: rgb(43,69,84);
background: linear-gradient(90deg, rgba(43,69,84,1) 50%, rgba(32,52,64,1) 100%);
		position: absolute;
		top: -100vh;
		z-index: 4;
		box-shadow: inset 0.5vw 0 0.5vw gray;
		transition: top 0.5s;
		color: #00888f;
		font-size: 2.3vh;
		box-sizing: border-box;
		overflow: hidden;
	}
	.squareItemsOverlay img{
		height: 5vh;
	}
	.squareItemsOverlay h1{
		background: rgb(29,54,69);
background: linear-gradient(90deg, rgba(29,54,69,1) 50%, rgba(20,35,44,1) 100%);
		box-shadow: inset 0.5vw 0.15vw 0.5vw gray;
		padding: 1vh 1vh 1vh;
		font-size: 8vw;
	}
	.squareItemsOverlayText{
		width: 100%;
		box-sizing: border-box;
		padding: 0 1vw 10vh;
		overflow-y: auto;
		height:calc(89vh - 5.5vh - 6.5vw);
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		color: #87a8b0;
		font-weight: bold;
		opacity: 0;
		animation: 0.5s linear appearSlowly forwards;
		animation-delay: 1s;
	}
	.squareItemsOverlayFooter{
		width: 100%;
		display: inline-flex;
		flex-direction: row;
		justify-content: space-around;
		font-size: 8vw;
		box-sizing: border-box;
		padding: 2vh 10vw 2.5vh;
		position: absolute;
		bottom: 0;
		left: 0;
		background: rgb(29,54,69);
background: linear-gradient(90deg, rgba(29,54,69,1) 50%, rgba(20,35,44,1) 100%);
		box-shadow: inset 0.5vw -0.15vw 0.5vw gray;
		opacity: 0;
		animation: 0.5s linear appearSlowly forwards;
		animation-delay: 0.5s;
	}
	.squareItemsOverlayFooter a{
		color: #00888f;
		transition: color 0.5s;
	}
	.iconBar{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		color: #00888f;
		box-sizing: border-box;
		font-size: 4vw !important;
		animation: 2s linear appearSlowly forwards;
		cursor: default;
		background-color: transparent;
		padding:0 0 3vh;
	}
	.iconBar p{
		width: 100%;
		padding:0.25vh 0 0.25vh;
	}
	.sqTOcir1{
		animation: sqTOcir infinite 10s;
	}
	.sqTOcir2{
		animation: sqTOcir infinite 10s;
		animation-delay: 2.5s;
	}
	.sqTOcir3{
		animation: sqTOcir infinite 10s;
		animation-delay: 5s;
	}
	.sqTOcir4{
		animation: sqTOcir infinite 10s;
		animation-delay: 7.5s;
	}
	.backAndForth1{
		animation: swing infinite 2.5s;
	}
	.backAndForth2{
		animation: swing infinite 2.5s;
		animation-delay: 1.25s;
	}
	.contactBtnHome{
		width: 80vw;
		margin:0 10vw 3vh;
		color: white;
		background-color: #0d161c;
		z-index: 5;
		padding: 1.5vw 0.5vw 1.5vw;
		box-sizing: border-box;
		font-size: 7.5vw;
		font-weight: bold;
		border-radius: 1.5vw;
		cursor: pointer;
		box-shadow: 0 0 0 .5vh white,0 0 5vw 1vw black;
		transition: transform 0.25s, filter 0.25s, background 0.25s, box-shadow 0.25s;
		-webkit-user-select: none; /* Safari */        
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */
	}
	.emailPrompt{
		display: none;
	}
}
@keyframes appearSlowly {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideIn {
  0% {
    bottom:-100vh;
  }
  50% {
    bottom:-100vh;
  }
  75% {
   bottom:-50vh;
  }
  100% {
    bottom:18vh;
  }
}
@keyframes swing {
	0%{
		transform: translate(-2.5vw,0);
	}
	50%{
		transform: translate(2.5vw,0);
	}
	100%{
		transform: translate(-2.5vw,0);
	}
}

@keyframes sqTOcir {
	0%{
		transform: rotate(0deg);
	}
	13%{
		transform: rotate(0deg);
	}
	14%{
		transform: rotate(-2.5deg);
	}
	15%{
		transform: rotate(2.5deg);
	}
	16%{
		transform: rotate(-2.5deg);
	}
	17%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(0deg);
	}
}
@keyframes swingOnce {
	0%{
		transform: rotate(0deg);
	}
	25%{
		transform: rotate(-2.5deg);
	}
	50%{
		transform: rotate(2.5deg);
	}
	75%{
		transform: rotate(-2.5deg);
	}
	100%{
		transform: rotate(0deg);
	}
}
@keyframes slideInServBtn {
  0% {
    bottom:-10vh;
  }
  100% {
    bottom: 5vh;
  }
}
@keyframes slideInIconBar {
  0% {
    bottom:-10vh;
  }
  100% {
    bottom: 12vh;
  }
}