@media (min-aspect-ratio: 10/10){
	/* width */
	::-webkit-scrollbar {
	  width: 0.5vw;
	}

	/* Track */
	::-webkit-scrollbar-track {
	  background: transparent;
	}
	 
	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #00888f; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: #00888f; 
	}
	.hosting{
		width: 100vw;
		height: 89vh;
		box-sizing: border-box;
		overflow: hidden;
		opacity: 0;
		animation: 2s linear appearSlowly forwards;
		animation-delay: 1s;
	}
	.imageContainer{
		width: 30vw;
		height: 100%;
		background-image: url("../public/assets/server_img.png");
		background-position: center;
 	 	background-repeat: no-repeat;
 	 	background-size: cover;
 	 	border-right: 2px solid #00888f;
 	 	display: inline-block;
 	 	box-sizing: border-box;
 	 	z-index: 6 !important;
	}
	.textContainer{
		width: 70vw;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		display: inline-block;
		box-sizing: border-box;
		padding: 0 2vw 0;
		text-align: left;
		font-size: 0.25vw;
	}
	.textContainer h1{
		font-size: 3vw;
		color: #00888f;
	}
	.textContainer h2{
		font-size: 2vw;
		color: #3a5c5e;
	}
	.textContainer p{
		font-size: 1.3vw;
		color: #878484;
	}
	.textContainerOwnBusinessText{
		color: #1f1f1f !important;
		background-color: #00888f;
		border-radius: 1vw;
		padding: 0.5vw 0.5vw 0.5vw;
		margin-bottom: 0.25vw;
	}
}
@media (max-aspect-ratio: 10/10){
	.hosting{
		width: 100vw;
		height: 89vh;
		box-sizing: border-box;
		overflow: hidden;
		background-image: url("../public/assets/serverBackgr.jpeg");
		background-position: center;
 	 	background-repeat: no-repeat;
 	 	background-size: cover;
 	 	opacity: 0;
		animation: 2s linear appearSlowly forwards;
		animation-delay: 1s;
		position: absolute;
		top:6vh;
	}
	.imageContainer{
		display: none;
	}
	.textContainer{
		width: 90vw;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		display: inline-block;
		box-sizing: border-box;
		padding: 5vw 5vw 0;
		text-align: left;
		font-size: 0.25vw;
		background: #1f1f1f;
	}
	.textContainer h1{
		font-size: 10vw;
		color: #00888f;
	}
	.textContainer h2{
		font-size: 5vw;
		color: #3a5c5e;
	}
	.textContainer p{
		font-size: 5vw;
		color: #878484;
	}
	.textContainerOwnBusinessText{
		color: #1f1f1f !important;
		background-color: #00888f;
		border-radius: 2vw;
		padding: 0.5vw 0.5vw 0.5vw;
		margin-bottom: 25vw;
	}
}
@keyframes appearSlowly {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}