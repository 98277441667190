@media (min-aspect-ratio: 10/10){
	/* width */
	::-webkit-scrollbar {
	  width: 0.5vw;
	}

	/* Track */
	::-webkit-scrollbar-track {
	  background: transparent;
	}
	 
	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #00888f; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: #00888f; 
	}
	.videoContainer{
		width: 100%;
		height: 89vh;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		box-sizing: border-box;
		opacity: 0;
		animation: 2s linear appearSlowly forwards;
		animation-delay: 1s;
		min-height: 300px;
		min-width: 600px;
		color: white;
		overflow: hidden;
		transition: opacity 0.25s;
	}
	.video{
		opacity: 1;
		transition: opacity 0.4s;
	}
	.containerRigth{
		height:89vh;
		width: 20vw;
		box-sizing: border-box;
		color: white!important;
		position: relative;
		overflow-y: auto;
		border-left:1px solid #00888f;
		background-color: black;
		background-image: url("../public/assets/serverBackgr.jpeg");
		background-position: center;
 	 	background-repeat: no-repeat;
 	 	background-size: cover;
	}
	.containerLeft{
		height:89vh;
		width: 90%;
		padding: 0 5vw 0;
		box-sizing: border-box;
		color: white!important;
		background-color: #0d161c;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-shadow: inset 0 50vh 15vh -45vh #333333,inset 0 -50vh 15vh -45vh #333333;
	}
	.containterRightrow{
		display: flex;
		flex-direction: column;

	}
	.containerRightItem{
		height:fit-content;
		width: 100%;
		box-sizing: border-box;
		background: transparent;
		padding: 1vw 1vw 1vw;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
		position: relative;
	}
	.containerRightItem img{
		max-width: 100%;
		border-radius: 0.5vw;
		box-sizing: border-box;
		box-shadow: 0 0 0 0.1vw #00888f,0 0 1vw #00888f;
		cursor: pointer;
		transition: 0.25s box-shadow, transform 0.25s;
	}
	.containerRightItem img:hover{
		transform: scale(1.1);
	}
	.containerRightItem img:active{
		transform: scale(1.05);
	}
	.videoPlayer{
		width: 100%;
		max-height: 89vh;
		box-sizing: border-box;
		border-radius: 0.5vw;
		box-shadow: 0 0 2vw #00888f;
	}
}
@media (max-aspect-ratio: 10/10){
	.videoContainer{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		opacity: 0;
		animation: 1s linear appearSlowly forwards;
		color: white;
		transition: opacity 0.49s;
		overflow: hidden;
		height: 89vh;
		position: absolute;
		top:6vh;
	}
	.video{
		opacity: 1;
		transition: opacity 0.4s;
	}
	.containerRigth{
		width: 100%;
		height:79vh;
		box-sizing: border-box;
		color: white!important;
		position: relative;
		overflow-y: auto;
		padding-bottom: 20vh;
	}
	.containerLeft{
		width: 100%;
		box-sizing: border-box;
		color: white!important;
		background-color: #f7e2a8;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	.containterRightrow{
		display: block;
		margin: 0;
		padding: 0;

	}
	.containerRightItem{
		width: 100%;
		box-sizing: border-box;
		background: transparent;
		padding: 1vh 5% 1vh;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
		position: relative;
	}
	.containerRightItem img{
		max-width: 100%;
		border-radius: 2.5vw;
		border: 2px solid white;
		box-sizing: border-box;
		cursor: pointer;
		transition: 0.5s box-shadow, filter 0.5s;
	}
	.videoPlayer{
		width: 100%;
		border: 4px solid white;
		box-sizing: border-box;
	}
}
@keyframes appearSlowly {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}