*{
  padding: 0;
  margin: 0;
}
button{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
html{
  overflow: hidden;
  max-height: 100vh;
}
p{
  white-space: pre-line;
}
.App {
  position: absolute;
  top:0;
  left: 0;
  text-align: center;
  background-color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: inset 0 50vh 50vh -20vh #00888f;
  transition: background-color 2s, box-shadow 2s;
  overflow: hidden;
}
.defaultScreen{
  width: 100%;
  height:100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
}


.mainPartition{
  height: 89vh;
  width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  opacity: 0;
  animation: 3s linear appearSlowly forwards;
  z-index: 1;
}

@keyframes  btnEffect {
  from {
    background-color: white;
  }
  to {
    background-color: #00888f;
  }
}
.navItem{
  width: 4vh;
  height: 4vh;
  color: white;
  background-color:#0d161c;
  box-sizing: border-box;
  margin: .5vh 1vh .5vh;
  border-radius: 50%;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.5vh 0.5vh 0.5vh;
  box-shadow: 0 0 0 0 #0d161c;
  transition: background-color 0.25s, color  0.25s, box-shadow 0.25s;
}
.navItem:hover{
  color: #0d161c;
  background-color: white;
  box-shadow: 0 0 0 0.4vh #0d161c;
}
.navItem:active{
  color: #0d161c;
  background-color: white;
  box-shadow: 0 0 0 .6vh #0d161c;
}
.activeNavItem{
  width: 4vh;
  height: 4vh;
  color: white;
  background-color: #00888f;
  box-shadow: 0 0 0 0.4vh #00888f;
  box-sizing: border-box;
  margin: .5vh 1vh .5vh;
  border-radius: 50%;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.5vh 0.5vh 0.5vh;
  transition: background-color 0.25s, color  0.25s, box-shadow 0.25s;
  animation: 5s linear beat forwards;
} 


@media (min-aspect-ratio: 10/10){
  .reactLogo{
    height: 5vh;
    box-sizing: border-box;
    margin: 0.5vh 1vh 0.5vh;
    display: inline-block;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    top: 0;
    right:2vh;
    animation: 10s linear App-logo-spin infinite;
  }
  .reactText{
    height: 5vh;
    box-sizing: border-box;
    margin: 0.5vh 1vh 0.5vh;
    display: inline-block;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    top: 0;
    right:2vh;
    cursor: pointer;
  }
  .mainPartition{
    height: 89vh;
    width: 100vw;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    opacity: 0;
    animation: 3s linear appearSlowly forwards;
    z-index: 1;
  }
  .tabContainer{
    min-width : 20vh;
    width: 20%;
    display: flex; 
    flex-direction: row;
    justify-content: space-around;
    box-sizing: border-box;
  }
  .tabId{
    position: absolute;
    top:calc(2.5vh - 0.75vw);
    left: calc(5vh + 2vw);
    margin: 0 1vh 0;
    border-bottom-right-radius: 0.5vw;
    color: #0d161c;
    z-index: 4;
    font-size: 1.5vw;
    box-sizing: border-box;
    cursor: default;
    border-radius: 0.5vw;
    background-color: white;
    padding: 0 0.5vw 0;
  }
  .cornerLogo{
    height: 5vh;
    box-sizing: border-box;
    margin: 0.5vh 1vh 0.5vh;
    display: inline-block;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    top: 0;
    left:2vh;
  }
  .language{
    position: absolute;
    right: calc(3vw + 5vh);
    font-size: 1vw;
    font-weight: bold;
    border-radius: 0.5vw;
    overflow: hidden;
    background-color: #0d161c;
    color: white;
    padding-left: 0.5vw;
    cursor: default;
  }
  .language p{
    display: inline-block;
    padding-right: 0.5vw;
  }
  .languageSelection{
    outline: none;
    border:none;
    font-size: 1vw;
    font-weight: bold;
    height:5vh;
    display: inline-block;
    background-color: #00888f;
    color: white;
    cursor: pointer;
    padding-left: 0.25vw;
  }
  .languageSelection option{
    background-color: white;
    color: #0d161c;
  }
  .header-nav{
    height: 5vh;
    width: 100vw;
    background-color: white;
    color:#0d161c;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
    padding: 0.5vh 0 0.5vh;
    z-index: 3;
    text-align: center;
    box-shadow: 0 0 0.75vw black;
  }
  .footer{
    height: 5vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: white;
    color: #0d161c;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    overflow: hidden;
    z-index: 1;
    position: absolute;
    bottom: 0;
    box-shadow: 0 0 0.75vw black;
  }
  .menuOverlayMobile{
    display: none;
  }
  .mobileNoNavItems{
    display: inline-block;
  }
  .mobileOnlyNavItems{
    display: none;
  }
  .permissions{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(0, 136, 143, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: white;
    font-size: 2vw;
    overflow-y: auto;
  }
  .permissionCard{
    position: relative;
    width: 40vw;
    border-radius: 0.5vw;
    background-color: rgba(13, 22, 28, 1);
    box-shadow: 0 0 2vw 0.5vw black, 0 0 0 0.25vw inset #00888f;
    box-sizing: border-box;
    padding: 0.5vw 0.5vw 0.5vw;
    margin-left: 30vw;
    cursor: default;
  }
  .permissionCard h2{
    color: white;
    background-color: #00888f;
    border-radius: 0.25vw;
  }
  .permissionButton{
    border-radius: 0.5vw;
    border: 0.25vw solid white;
    box-sizing: border-box;
    width: 10vw;
    margin: 1vw 2vw 1vw;
    transition: background 0.25s, color 0.25s;
  }
  .permissionButton:hover{
    background-color: white;
    color: #0D161C;
  }
  .permissionButton:active{
    filter: brightness(0.6);
  }
  .permissionCloseButton{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5vw;
    font-weight: bold;
    width: 2vw;
    height: 2vw;
    margin: 1.5vw 0.75vw 0.75vw;
    color: #0D161C;
    background-color: white;
    border-radius: 50%;
    transition: filter 0.25s;
    cursor: pointer;
  }
  .permissionCloseButton:hover{
    filter: brightness(0.6);
  }
  .permissionCloseButton:active{
    filter: brightness(0.45);
  }
  .permInfoOverlay{
    position: absolute;
    left: 0;
    top: 0;
    width:100vw;
    height: 100vh;
    opacity: 1;
    background-color: rgba(0, 34, 36, 0.5);
    border-radius: 1vw;
    z-index: 10;
    visibility: hidden;
    font-size: 15vw;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  }
}
@media (max-aspect-ratio: 10/10){
  .reactLogo{
    display: none;
  }
  .reactText{
    display: block;
    position: absolute;
    top:1vh;
    right: 1vh;
    width: 4vh;
  }
  .mainPartition{
    height: 89%;
    width: 100vw;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    opacity: 0;
    animation: 3s linear appearSlowly forwards;
    z-index: 1;
    padding-bottom: 6vh;
  }
  .tabContainer{
    max-width : 100%; 
    display: inline-flex; 
    flex-direction: row;
  }
  .tabId{
    position: absolute;
    top:6vh;
    left: 0;
    background-color: white;
    padding: 0.5vw 1vw 0.5vw;
    border-bottom-right-radius: 1vw;
    color: #0d161c;
    z-index: 3;
    font-size: 4vw;
    box-sizing: border-box;
  }
  .navItem{display: none}
  .activeNavItem{display: none;}
  .cornerLogo{
    display: none;
  }
  .language{
    position: absolute;
    right: 25vw;
    font-size: 4vw;
    font-weight: bold;
    border-radius: 2vw;
    overflow: hidden;
    background-color: #0d161c;
    color: white;
    padding-left: 0.5vw;
    cursor: default;
    width: 50vw;
    height:5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .language p{
    display: inline-block;
    padding-right: 0.5vw;
    padding-left: 2vw;
  }
  .languageSelection{
    outline: none;
    border:none;
    font-size: 4vw;
    font-weight: bold;
    height:5vh;
    display: inline-block;
    background-color: #00888f;
    color: white;
    cursor: pointer;
    padding-left: 0.25vw;
    width: 50%;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
  }
  .languageSelection option{
    background-color: white;
    color: #0d161c;
  }
  .header-nav{
    height: 5vh;
    width: 100vw;
    background-color: white;
    color:#0d161c;
    display: block;
    padding: 0.5vh 0 0.5vh;
    z-index: 5;
    text-align: left;
    overflow: hidden;
    box-shadow: 0 0 1vh black;
  }
  .footer{
    height: 5vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: white;
    color: #0d161c;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    overflow: hidden;
    z-index: 5;
    position: absolute;
    bottom: 0;
    box-shadow: 0 0 1vh black;
  }
  .menuOverlayMobile{
    width: 100%;
    height: 100%;
    position: absolute;
    top: -100%;
    left: 0;
    background-color: #1f1f1f;
    z-index: 4;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    transition: top 1s;
  }
  .menuBtn{
    color: gray;
    padding: 2vh 0 2vh;
  }
  .closeBtnMenu{
    position: absolute;
    top: 0vw;
    right: 5vw;
    font-size: 10vw;
    z-index: 5;
    color: gray;
  }
  .mobileNoNavItems{
    display: none;
  }
  .mobileOnlyNavItems{
    display: inline-block;
  }
  .menutitle{
    color: white;
    text-decoration: underline;
  }
  .permissions{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba(0, 136, 143, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: white;
    font-size: 5vw;
    overflow-y: auto;
  }
  .permissionCard{
    position: relative;
    width: 80vw;
    border-radius: 1.5vw;
    background-color: rgba(13, 22, 28, 1);
    box-shadow: 0 0 5vw 1.5vw black, 0 0 0 0.75vw inset #00888f;
    box-sizing: border-box;
    padding: 0.5vw 0.5vw 0.5vw;
    margin-left: 10vw;
    cursor: default;
  }
  .permissionCard h2{
    color: white;
    background-color: #00888f;
    border-radius: 0.5vw;
  }
  .permissionButton{
    border-radius: 1.5vw;
    border: 0.25vw solid white;
    box-sizing: border-box;
    width: 25vw;
    margin: 1vw 2vw 1vw;
    transition: background 0.25s, color 0.25s;
  }
  .permissionCloseButton{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 5vw;
    font-weight: bold;
    width: 6.5vw;
    height: 6.5vw;
    margin: 2vw 2vw 1.5vw;
    color: #0D161C;
    background-color: white;
    border-radius: 50%;
    transition: filter 0.25s;
  }
  .permInfoOverlay{
    position: absolute;
    left: 0;
    top: 0;
    width:100vw;
    height: 100vh;
    opacity: 1;
    background-color: rgba(0, 34, 36, 0.75);
    z-index: 10;
    visibility: hidden;
    font-size: 75vw;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  }
}
@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes beat {
  0% {
    width:4vh;
    height: 4vh;
    margin: 0.5vh 1vh 0.5vh;
    background-color: #00888f;
    box-shadow: 0 0 0 0.4vh #00888f;
  }
  2% {
    width: 3vh;
    height: 3vh;
    margin: 1.25vh 1.5vh 1.25vh;
    background-color:#0d161c;
    box-shadow: 0 0 0 0.4vh #0d161c;
  }
  6% {
    width: 4vh;
    height: 4vh;
    margin: 0.5vh 1vh 0.5vh;
    background-color: #00888f;
    box-shadow: 0 0 0 0.4vh #00888f;
  }
}
.appearSlowly{
  animation: 5s linear appearSlowly forwards;
}
@keyframes appearSlowly {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}






.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: 2px black;
  background-color: black;
  will-change: opacity, transform;
  border-radius: 0.5vw;
  overflow: hidden;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  background-color: black;
  border: none;
}
.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}