@media (min-aspect-ratio: 10/10){
	/* width */
	::-webkit-scrollbar {
	  width: 0.5vw;
	}

	/* Track */
	::-webkit-scrollbar-track {
	  background: transparent;
	}
	 
	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #00888f; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: #00888f; 
	}
	.graphics{
		width: 100vw;
		height: 89vh;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: auto;
		opacity: 0;
		animation: 2s linear appearSlowly forwards;
		animation-delay: 1s;
	}
	.graphicaItem{
		box-sizing: border-box;
		margin: 2vw 5vw 2vw;
		box-shadow: 0 0 2vh black;
		width: 90vw;
		position: relative;
		display: flex;
		border-radius: 1vw;
		overflow: hidden;
		background-color: lightgray;
		box-sizing: border-box;
		background: rgb(159,186,187);
background: linear-gradient(0deg, rgba(159,186,187,1) 0%, rgba(198,216,222,1) 25%, rgba(198,216,222,1) 75%, rgba(159,186,187,1) 100%);
	}
	.graphicaItemImgContainer{
		width:30%;
		min-height: 28vw;
		display: inline-flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		padding: 1vw 2vw 1vw;
	}
	.graphicaItemImgContainer img{
		width:100%;
		margin: 1vw 0 1vw;
		box-shadow: 0 0 0.5vw black;
		cursor: crosshair;
	}
	.graphicaItemTextContainer{
		width:70%;
		min-height: 28vw;
		display: inline-block;
		background-color: #1f1f1f;
		box-sizing: border-box;
		text-align: left;
		padding: 1vw 2vw 1vw;
		color: #00888f;
		cursor: default;
	}
	.graphicaItemTextContainer h1{
		font-size: 2.25vw;
	}
	.graphicaItemTextContainer p{
		font-size: 1.25vw;
		color: gray;
	}
	.zoomSquare{
		display: none;
		width: 25vw;
		height: 25vw;
		top:6vh;
		right: 0;
		position: absolute;
		box-sizing: border-box;
		overflow: hidden;
	}
	.zoomSquare img{
		height: 200%;
		position: absolute;
		left: 0;
		top: 0;
	}
	.onlyBigScreen{
		display: bock;
	}
	.photoExample{
		width:40%;
		margin: 0 30% 0;
		font-size: 1.3vw;
		background-color: #1f1f1f;
		color: white;
		box-sizing: border-box;
		padding: 0.25vw 0.25vw 0.25vw;
		border-radius: 0.25vw;
		cursor: pointer;
		margin-top: 0.5vw;
		-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
	}
	.photoExample:hover{
		filter: brightness(0.8);
	}
	.photoExample:active{
		filter: brightness(0.6);
	}
	.exampleImg{
		height: 80vh;
		box-shadow: 0 0 1.5vw black;
		background-color: white;
		border-radius: 1vw;
		overflow: hidden;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.5s;
	}
	.exampleImgContainer{
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		background-color: rgba(71, 103, 122, 0.5);
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.25s;
	}
	.centerIt{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	.exampleImgFrame{
		height: fit-content;
		width: fit-content;
		position: relative;
	}
}
@media (max-aspect-ratio: 10/10){
	.graphics{
		width: 100vw;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: auto;
		opacity: 0;
		animation: 2s linear appearSlowly forwards;
		animation-delay: 1s;
		padding-bottom: 5vh;
		opacity: 0;
		animation: 1s linear appearSlowly forwards;
		animation-delay: 1s;
	}
	.graphicaItem{
		box-sizing: border-box;
		margin: 15vw 5vw 15vw;
		box-shadow: 0 0 2vh black;
		width: 90vw;
		position: relative;
		display: flex;
		flex-direction: column;
		border-radius: 3vw;
		overflow: hidden;
		background: rgb(159,186,187);
background: linear-gradient(90deg, rgba(159,186,187,1) 0%, rgba(198,216,222,1) 25%, rgba(198,216,222,1) 75%, rgba(159,186,187,1) 100%);
	}
	.graphicaItemImgContainer{
		width:100%;
		min-height: 28vw;
		display: inline-flex;
		flex-direction: column;
		justify-content: space-around;
		box-sizing: border-box;
		padding: 3vw 5vw 3vw;
	}
	.graphicaItemImgContainer img{
		width:100%;
		margin: 3vw 0 3vw;
		box-shadow: 0 0 1vw black;
	}
	.graphicaItemTextContainer{
		width:100%;
		min-height: 28vw;
		display: inline-block;
		background-color: #1f1f1f;
		box-sizing: border-box;
		text-align: left;
		box-sizing: border-box;
		padding: 1vw 2vw 1vw;
		color: #00888f;
		cursor: default;
	}
	.graphicaItemTextContainer h1{
		font-size: 6vw;
	}
	.graphicaItemTextContainer p{
		font-size: 4vw;
		color: gray;
		padding-bottom: 2vw;
	}
	.zoomSquare{
		display: none;
	}
	.zoomSquare img{
		display: none;
	}
	.onlyBigScreen{
		display: none;
	}
	.photoExample{
		width:50%;
		margin: 0 25% 0;
		font-size: 5vw;
		background-color: #1f1f1f;
		color: white;
		box-sizing: border-box;
		padding: 0.25vw 0.25vw 0.25vw;
		border-radius: 5vw;
		cursor: pointer;
		-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
	}
	.exampleImg{
		width: 95vw;
		box-shadow: 0 0 1.5vw .5vw #00888f;
		background-color: white;
		border-radius: 2.5vw;
		overflow: hidden;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.5s;
	}
	.exampleImgContainer{
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		background-color: rgba(71, 103, 122, 0.5);
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.25s;
	}
	.centerIt{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	.exampleImgFrame{
		height: fit-content;
		width: fit-content;
		position: relative;
	}
}
.toCircle{
	border-radius: 50%;
	cursor: default !important;
}
@keyframes appearSlowly {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}