@media (min-aspect-ratio: 10/10){
	.promoContainer{
		width:100%;
		background-color:rgba(0,0,0,0);
		z-index:4;
		flex-direction:row;
		justify-content:space-around;
	}
	.promoBody{
		width: 25vw;
		height: 25vw;
		position:relative;
		color:white;
		font-size: 10vw;
		border-radius:0.5vw;
		box-sizing: border-box;
		background-size: cover;
		background: rgba(255,255,255,0.5);
	}
	.closePromoBtn{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: #BD0000;
		color: white;
		font-size: 30px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		cursor: pointer;
		position: absolute;
		top:10px;
		right: 10px;
		box-sizing: border-box;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		transition: background 0.25s, color 0.25s, border 0.25s, filter 0.25s;
	}
	.closePromoBtn:hover{
		background-color: #00888f;
	}
}
@media (max-aspect-ratio: 10/10){
	.promoContainer{
		width:100%;
		background-color:rgba(0,0,0,0);
		z-index:4;
		flex-direction:column;
		justify-content:space-around;
		padding: 1vh 0 1vh;
	}
	.promoBody{
		width: 65vw;
		height: 65vw;
		position:relative;
		margin-left:7.5vw;
		color:white;
		font-size: 30vw;
		border-radius:1.5vw;
		background: rgba(255,255,255,0.5);
		background-size: cover;
		box-sizing: border-box;
	}
	.closePromoBtn{
		width: 8vw;
		height: 8vw;
		border-radius: 50%;
		background-color: #00888f;
		color: white;
		font-size: 6vw;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		cursor: pointer;
		position: absolute;
		top:2vw;
		right: 2vw;
		box-sizing: border-box;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
}